import React from 'react';
import contactPageStyles from '../../styles/ContactPage.module.css';

const Contact = () => {
    return (
        <div className="standardPage">
            <div className="pageContent">
                <div className="mainSection">
                    <div className="contentHeader">Contact</div>
                    <div className={contactPageStyles.card}>
                        <div className={contactPageStyles.contactItem}>Phone: (416) 230-7190</div>
                        <div className={contactPageStyles.contactItem}>Alt Phone: (647) 704-5383</div>
                        <div className={contactPageStyles.contactItem}>Email: caqsoccer@gmail.com</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;