import React, { Component } from "react";
import NewsPost from '../NewsPost';
import CrudButton from '../CrudButton';
import AddPostModal from '../AddPostModal';
import InstagramPost from "../InstagramPost";
import { AuthContext } from '../AuthContext';
import { ModalContext } from '../ModalContext';
import axios from 'axios';
import soccerMaxBanner from '../../assets/images/soccermaxx_banner.png';
import sickKidsBanner from '../../assets/images/sickkids_banner.jpeg';

class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newsPosts: [],
            instagramPosts: []
        }
    }

    componentDidMount() {
        axios.get('/api/newsposts')
        .then(resp => {
            const success = resp.data.success;
            if (success) {
                this.setState({
                    newsPosts: resp.data.data
                });
            } else {
                return;
            }
        })
        .catch(error => {
            console.log(error);
        });

        axios.get('/api/instagram/posts')
        .then(resp => {
            const success = resp.data.success;
            if (success) {
                this.setState({
                    instagramPosts: resp.data.instagram_posts
                });
            } else {
                console.log("Instagram posts error");
                return;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {
        
        const crudButtonStyles = {
            position: "absolute",
            top: "32px",
            right: "20px"
        }

        return (
            <AuthContext.Consumer>
                {({isAuth}) => (
                    <ModalContext.Consumer>
                        {({setShowModal, setModalChildComponent}) => (
                            <div className="landingPage">
                                <div className="splashImage">
                                    <div className="splashText">Play through passion.</div>
                                </div>
                                <div className="instagramPosts">
                                    {this.state.instagramPosts.map(instagramPost => {
                                        return (<InstagramPost key={instagramPost.id} media_type={instagramPost.media_type} media_url={instagramPost.media_url} />)
                                    })}
                                </div>
                                <div className="pageContent column">
                                    <div className="leftSection">
                                        <div className="contentHeader">Latest news</div>
                                        {isAuth &&
                                        <CrudButton classNames={["green"]} style={crudButtonStyles} onClick={() => {
                                            setModalChildComponent(<AddPostModal />);
                                            setShowModal(true);
                                        }}>Add Post</CrudButton>
                                        }
                                        <div className="newsPosts">
                                        {this.state.newsPosts.map(newsPost => {
                                            return (<NewsPost key={newsPost.id} id={newsPost.id} title={newsPost.title} createdAtDate={newsPost.createdAt} imageUrl={newsPost.image_url} authenticated={isAuth}>{newsPost.content}</NewsPost>)
                                        })}
                                        </div>
                                    </div>
                                    <div className="rightSection">
                                        <div className="rightSectionItem">
                                            <div className="itemTitle">Training Video</div>
                                            <div className="trainingVideo">
                                                <iframe width="250" height="250"
                                                    src="https://www.youtube.com/embed/8eT1NQy1rA4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                                                </iframe>
                                            </div>
                                        </div>
                                        {/* Social Media */}
                                        <div className="rightSectionItem">
                                            <div className="itemTitle">Social Media</div>
                                            <div className="socialMediaRow">
                                                <div className="socialMediaIcon instagram"></div>
                                                <a href="https://www.instagram.com/c.a.q.soccer/?utm_source=ig_embed&ig_rid=598db039-58c7-4d00-a74c-27134ca291fa" target="_blank" rel="noreferrer"><div className="socialMediaText">@c.a.q.soccer</div></a>
                                            </div>
                                            <div className="socialMediaRow">
                                                <div className="socialMediaIcon facebook"></div>
                                                <a href="https://www.facebook.com/Caqsoccer/" target="_blank" rel="noreferrer"><div className="socialMediaText">@Caqsoccer</div></a>
                                            </div>
                                        </div>
                                        {/* Sponsors */}
                                        {/* <div className="rightSectionItem">
                                            <div className="itemTitle">Sponsorships</div>
                                            <img className="sponsorBanner soccerMaxx" src={soccerMaxBanner} alt="SoccerMaxx Banner" />
                                            <img className="sponsorBanner sickKids" src={sickKidsBanner} alt="SickKids Banner" />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </ModalContext.Consumer>
                )}
            </AuthContext.Consumer>
        );
    }
};

export default HomePage;