import React from 'react';
import instagramPostStyles from '../styles/InstagramPost.module.css';

const InstagramPost = (props) => {

    if (props.media_type === "VIDEO") {
        return (
            <div className={instagramPostStyles.instagramPost}>
                <video src={props.media_url} width="310" controls>
                    Your browser does not support the video tag.
                </video>
                <a href="https://www.instagram.com/c.a.q.soccer" target="_blank" rel="noopener noreferrer">
                    <div className={instagramPostStyles.caption}>
                        <div className={instagramPostStyles.instaLogo}></div>
                        <div className={instagramPostStyles.text}>@c.a.q.soccer</div>
                    </div>
                </a>
            </div>
        );
    } else if (props.media_type === "IMAGE" || props.media_type === "CAROUSEL_ALBUM") {
        return (
            <div className={instagramPostStyles.instagramPost}>
                <div className={instagramPostStyles.imagePost} style={ { backgroundImage: `url(${props.media_url})` } } />
                <a href="https://www.instagram.com/c.a.q.soccer" target="_blank" rel="noopener noreferrer">
                    <div className={instagramPostStyles.caption}>
                        <div className={instagramPostStyles.instaLogo}></div>
                        <div className={instagramPostStyles.text}>@c.a.q.soccer</div>
                    </div>
                </a>
            </div>
        );
    }

    return "";
};

export default InstagramPost;