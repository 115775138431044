import React from 'react';
import galleryPageStyles from '../../styles/GalleryPage.module.css';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}
const images = importAll(require.context('../../assets/images/gallery', false, /\.(png|jpe?g|svg)$/));

const GalleryPage = () => {
    return (
        <div className="standardPage">
            <div className="pageContent">
                <div className="mainSection">
                    <div className="contentHeader">Gallery</div>
                    <div className={galleryPageStyles.content}>
                        {Object.keys(images).map(imageKey => {
                            return (
                                <img src={images[imageKey].default} alt="Gallery"/>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GalleryPage;