import React from 'react';
import aboutPageStyles from '../../styles/AboutPage.module.css';

const AboutPage = () => {
    return (
        <div className="standardPage">
            <div className="pageContent">
                <div className="mainSection">
                    <div className={aboutPageStyles.infoContainer}>
                        <div className={aboutPageStyles.left}></div>
                        <div className={aboutPageStyles.right}>
                            <div className="contentHeader">CAQ Soccer Academy</div>
                            <div className={aboutPageStyles.textSection}>This program is based on the South American style of soccer, which focuses on good ball skills, physical conditioning and player positioning. Admirable traits and habits such as working hard, leadership and responsibility are encouraged throughout training. CAQ Soccer Training is all about true passion for the game run by one of the most passionate and dedicated coach you will meet.</div>

                            <div className={aboutPageStyles.mediumHeader}>About Carlos Quiroga</div>
                            <div className={aboutPageStyles.textSection}>Carlos was born in Argentina where he started to play professional soccer at the age of 17.  Carlos had the fortunate opportunity to train under some of the best coaches in Argentina.  Carlos brings this experience to his soccer-training program.</div>

                            <div className={aboutPageStyles.mediumHeader}>Professional Teams</div>
                            <div className={aboutPageStyles.textSection}>
                                <ul>
                                    <li>Andes Talleres – Mendoza - Champions</li>
                                    <li>Mendoza National Team</li>
                                    <li>Union de Santa Fe</li>
                                    <li>Ginnasia Esgrima de Mendoza</li>
                                    <li>Buffalo Stallions MISL</li>
                                    <li>Toronto Italia N.S.L. – Cup Champions</li>
                                    <li>Lujan Mendoza</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;