import React from 'react';
import registerPageStyles from '../../styles/RegisterPage.module.css';

const RegisterPage = () => {
    return (
        <div className="standardPage">
            <div className="pageContent">
                <div className="mainSection">
                    <div className="contentHeader">Register</div>
                    <div className={registerPageStyles.content}>Please contact us to inquire about registration.</div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;