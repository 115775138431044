import React, { useState, useContext } from 'react';
import scheduleItemStyles from '../styles/ScheduleItem.module.css';
import DeletePostModal from '../components/DeletePostModal';
import CrudButton from '../components/CrudButton';
import { ModalContext } from '../components/ModalContext';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const ScheduleItem = (props) => {

    const [state, setState] = useState({
        isEditMode: false,
        editButtonText: "Edit",
        title: props.title,
        subtitle: props.subtitle,
        content: props.children
    });

    const { setShowModal, setModalChildComponent } = useContext(ModalContext);
    const history = useHistory();

    const CancelCrudButtonStyles = {
        marginRight: "10px"
    }

    const handleDeletePostButtonClick = (e) => {
        e.preventDefault();

        setModalChildComponent(<DeletePostModal id={props.id} title={props.title} callback={deleteModalCallback} />)
        setShowModal(true);
    }

    const deleteModalCallback = () => {
        axios.delete(`/api/schedules/${props.id}`)
        .then(resp => {
            setShowModal(false);
            setModalChildComponent("");
            history.go(0);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const handleEditPostButtonClick = (e) => {
        if (state.isEditMode) {

            if (state.content == null || state.content.length < 1) {
                console.log("Description cannot be empty.");
                return;
            }
    
            axios.put(`/api/schedules/${props.id}`, {
                title: state.title,
                subtitle: state.subtitle,
                content: state.content
            })
            .then(resp => {
                history.go(0);
            })
            .catch(error => {
                console.log(error);
            });

        } else {
            setState(state => ({ ...state, isEditMode: true, editButtonText: "Save" }));
        }
    }

    const handleEditCancelButtonClick = (e) => {
        setState(state => ({ ...state, isEditMode: false, editButtonText: "Edit" }));
    }

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;

        setState(state => ({ ...state, [name]: e.target.value }));
    }

    return (
        <div className={scheduleItemStyles.scheduleContainer}>
            {state.isEditMode
            ? <input type="text" id="title" name="title" value={state.title} onChange={(e) => handleInputChange(e)}></input>
            : <div className={scheduleItemStyles.scheduleItemTitle}>{state.title}</div>
            }
            
            {state.isEditMode
            ? <input type="text" id="subtitle" name="subtitle" value={state.subtitle} onChange={(e) => handleInputChange(e)}></input>
            : <div className={scheduleItemStyles.scheduleItemSubtitle}>{state.subtitle}</div>
            }
            
            {state.isEditMode
            ? <input type="text" id="content" name="content" value={state.content} onChange={(e) => handleInputChange(e)}></input>
            : <div className={scheduleItemStyles.scheduleItemContent}>{state.content}</div>
            }
            
            {props.authenticated
            &&
            <div className={scheduleItemStyles.actionButtonsContainer}>
                {state.isEditMode
                && <CrudButton classNames={["grey"]} style={CancelCrudButtonStyles} onClick={handleEditCancelButtonClick}>Cancel</CrudButton>
                }
                <CrudButton classNames={["gold"]} onClick={handleEditPostButtonClick}>{state.editButtonText}</CrudButton>
            </div>
            }

            {props.authenticated && <div className={scheduleItemStyles.deleteButton} onClick={handleDeletePostButtonClick}>X</div>}
        </div>
    );
};

export default ScheduleItem;