import React, { useState, useContext, useRef } from 'react';
import EditPostModalStyles from '../styles/EditPostModal.module.css';
import CrudButton from './CrudButton';
import axios from 'axios';
import { ModalContext } from '../components/ModalContext';
import { useHistory } from 'react-router-dom';

const EditPostModal = (props) => {

    const [state, setState] = useState({
        title: props.title,
        description: props.description,
        imageFile: null,
        imageUrl: props.imageUrl
    });

    const { setShowModal, setModalChildComponent } = useContext(ModalContext);

    const history = useHistory();

    // Uncontrolled component
    const inputFile = useRef(null);

    const crudButtonStyles = {
        position: "relative",
        width: "100%",
        marginTop: "10px"
    }

    const crudButtonClearStyles = {
        position: "relative",
        width: "15%",
        height: "28px"
    }

    const onImageFileChange = (e) => {
        const target = e.target;
        const fileType = target.files[0].type;

        // Only allow image type files to be uploaded
        if (!fileType.includes("image")) {
            console.log("Only image type files are allowed");
            inputFile.current.value = "";
            setState(state => ({ ...state, imageFile: null, imageUrl: null}));
            return;
        }
        
        setState(state => ({
            ...state,
            imageFile: target.files[0],
            imageUrl: URL.createObjectURL(target.files[0])
        }));
    }

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setState(state => ({ ...state, [name]: e.target.value}));
    }

    const handleSubmitButtonClick = (e) => {
        e.preventDefault();

        if (state.title == null || state.title.length < 1 || state.description == null || state.description.length < 1) {
            return;
        }

        // Image uploading is optional
        let formData = new FormData();
        formData.append("title", state.title);
        formData.append("content", state.description);

        // No new image uploaded, don't upload the same image
        if (state.imageFile !== null) {
            formData.append("newsPostImage", state.imageFile);
        }

        axios({
            method: 'put',
            url: `/api/newsposts/${props.id}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        .then(function (response) {
            setShowModal(false);
            setModalChildComponent("");
            history.go(0);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleClearButtonClick = (e) => {
        e.preventDefault();

        // Clear image input file data
        inputFile.current.value = "";

        // Clear state
        setState(state => ({
            ...state,
            imageFile: "",
            imageUrl: ""
        }));
    }
    
    return (
        <div className={EditPostModalStyles.editPostModal}>
            <div className={EditPostModalStyles.title}>Edit Post</div>
            <input type="text" id="title" name="title" placeholder="Post Title" value={state.title} onChange={(e) => handleInputChange(e)}></input>
            <textarea type="text" id="description" name="description" placeholder="Post Description" value={state.description} onChange={(e) => handleInputChange(e)}></textarea>
            <div className={`${EditPostModalStyles.uploadedImage} ${state.imageUrl === "" ? "" : EditPostModalStyles.active}`} style={{backgroundImage: `url(${state.imageUrl})`}}></div>
            <div className={EditPostModalStyles.imageSelectorContainer}>
                <input className={EditPostModalStyles.imageSelector} type="file" ref={inputFile} onChange={(e) => onImageFileChange(e)} />
                <CrudButton classNames={["red"]} style={crudButtonClearStyles} onClick={handleClearButtonClick}>Clear</CrudButton>    
            </div>
            <CrudButton classNames={["gold"]} style={crudButtonStyles} onClick={handleSubmitButtonClick}>Save Changes</CrudButton>
        </div>
    );
};

export default EditPostModal;