import React, { Component } from "react";
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import tryoutPageStyles from '../../styles/TryoutPage.module.css';
import TryoutItem from '../TryoutItem';
import CrudButton from '../CrudButton';

class TryoutsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tryoutItems: [],
            newTitle: "",
            newSubtitle: "",
            newContent: "",
        }
    }

    componentDidMount() {
        axios.get('/api/tryouts')
        .then(resp => {
            const success = resp.data.success;
            if (success) {
                this.setState({
                    tryoutItems: resp.data.data
                });
            } else {
                return;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    handleInputChange(e) {
        const target = e.target;
        const name = target.name;
        this.setState({
            ...this.state,
            [name]: e.target.value
        });
    }

    handleSubmitButtonClick(e) {
        e.preventDefault();

        if (this.state.newContent == null || this.state.newContent.length < 1) {
            console.log("Description cannot be empty.");
            return;
        }

        axios.post('/api/tryouts', {
            title: this.state.newTitle,
            subtitle: this.state.newSubtitle,
            content: this.state.newContent
        })
        .then(resp => {
            this.setState({
                ...this.state,
                tryoutItems: resp.data.data
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {

        const crudAddButtonStyles = {
            position: "relative",
            marginTop: "10px"
        }

        return (
            <AuthContext.Consumer>
                {({isAuth}) => (
                    <div className="standardPage">
                        <div className="pageContent">
                            <div className="mainSection">
                                <div className="contentHeader">Tryouts</div>
                                <div className={tryoutPageStyles.textSection}>
                                    {this.state.tryoutItems.map(tryoutItem => {
                                        return (<TryoutItem key={tryoutItem.id} id={tryoutItem.id} title={tryoutItem.title} subtitle={tryoutItem.subtitle} authenticated={isAuth}>{tryoutItem.content}</TryoutItem>)
                                    })}
                                    {isAuth &&
                                        <div className={tryoutPageStyles.addNewTryoutsContainer}>
                                            <div>Add new tryout section:</div>
                                            <input type="text" id="newTitle" name="newTitle" placeholder="Title (Optional)" onChange={(e) => this.handleInputChange(e)}></input>
                                            <input type="text" id="newSubtitle" name="newSubtitle" placeholder="Subtitle (Optional)" onChange={(e) => this.handleInputChange(e)}></input>
                                            <textarea type="text" id="newContent" name="newContent" placeholder="Description" onChange={(e) => this.handleInputChange(e)}></textarea>
                                            <CrudButton classNames={["green"]} style={crudAddButtonStyles} onClick={(e) => this.handleSubmitButtonClick(e)}>Add</CrudButton>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </AuthContext.Consumer>
        );
    }
};

export default TryoutsPage;