import React, { useContext } from 'react';
import DeletePostModalStyles from '../styles/DeletePostModal.module.css';
import CrudButton from './CrudButton';
import { ModalContext } from '../components/ModalContext';

const DeletePostModal = (props) => {

    const crudButtonCancelStyles = {
        position: "relative",
        width: "100%",
        marginRight: "10px"
    }

    const crudButtonDeleteStyles = {
        position: "relative",
        width: "100%"
    }

    const { setShowModal, setModalChildComponent } = useContext(ModalContext);

    const handleDeleteButtonClick = (e) => {
        e.preventDefault();

        props.callback();
    }

    const handleCancelButtonClick = (e) => {
        setShowModal(false);
        setModalChildComponent("");
    }
    
    return (
        <div className={DeletePostModalStyles.deletePostModal}>
            <div className={DeletePostModalStyles.title}>Delete Post</div>
            <div className={DeletePostModalStyles.label}>Are you sure you want to delete this post &quot;{props.title}&quot;?</div>
            <div className={DeletePostModalStyles.buttonsContainer}>
                <CrudButton classNames={["grey"]} style={crudButtonCancelStyles} onClick={handleCancelButtonClick}>Cancel</CrudButton>
                <CrudButton classNames={["red"]} style={crudButtonDeleteStyles} onClick={handleDeleteButtonClick}>Delete</CrudButton>
            </div>
        </div>
    );
};

export default DeletePostModal;