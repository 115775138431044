import React from 'react';
import buttonStyles from '../styles/Button.module.css'

const CrudButton = (props) => {

    let classNames = buttonStyles.crudButton;
    props.classNames.forEach(className => {
        classNames += " " + buttonStyles[className];
    });
    
    return (
        <div className={classNames} onClick={props.onClick} style={props.style}>
            {props.children}
        </div>
    );
};

export default CrudButton;