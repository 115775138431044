import React from 'react';
import downloadsPageStyles from '../../styles/DownloadsPage.module.css';

const DownloadsPage = () => {
    return (
        <div className="standardPage">
            <div className="pageContent">
                <div className="mainSection">
                    <div className="contentHeader">Downloads</div>
                    <div className={downloadsPageStyles.downloadsContainer}>
                        <a href="../assets/files/guidelines_for_behaviour.pdf" download>
                            <div className={downloadsPageStyles.downloadItem}>
                                Guidelines For Behaviour
                            </div>
                        </a>
                        <a href="../assets/files/2_policy_report_suspicuos_child_abuse.pdf" download>
                            <div className={downloadsPageStyles.downloadItem}>
                                Policy Report Suspicuos Child Abuse
                            </div>
                        </a>
                        <a href="../assets/files/3_procedue_if_witness_inappropriate_behaviour_0.pdf" download>
                            <div className={downloadsPageStyles.downloadItem}>
                                Procedure if Witness Inappropriate Behaviour
                            </div>
                        </a>
                        <a href="../assets/files/policy_for_phyiscal_and_emotional_safety.pdf" download>
                            <div className={downloadsPageStyles.downloadItem}>
                                Policy For Phyiscal and Emotional Safety
                            </div>
                        </a>
                        <a href="../assets/files/1_safe_environment_rule_of_two.rtf" download>
                            <div className={downloadsPageStyles.downloadItem}>
                                Policy On Rule Of Two
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadsPage;