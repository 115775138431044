import React, { useContext } from 'react';
import newsPoststyles from '../styles/NewsPost.module.css';
import CrudButton from '../components/CrudButton';
import EditPostModal from '../components/EditPostModal';
import DeletePostModal from '../components/DeletePostModal';
import { ModalContext } from '../components/ModalContext';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const NewsPost = (props) => {

    const { setShowModal, setModalChildComponent } = useContext(ModalContext);
    
    const history = useHistory();

    const EditCrudButtonStyles = {
        position: "absolute",
        top: "0px",
        right: "0px"
    }
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const formattedCreatedAtDate = new Date(props.createdAtDate).toLocaleDateString("en-US", dateOptions)

    const handleEditPostButtonClick = (e) => {
        e.preventDefault();
        
        setModalChildComponent(<EditPostModal id={props.id} title={props.title} imageUrl={props.imageUrl} description={props.children} />)
        setShowModal(true);

    }

    const handleDeletePostButtonClick = (e) => {
        e.preventDefault();

        setModalChildComponent(<DeletePostModal id={props.id} title={props.title} callback={deleteModalCallback} />)
        setShowModal(true);
    }

    const deleteModalCallback = () => {
        axios.delete(`/api/newsposts/${props.id}`)
        .then(resp => {
            setShowModal(false);
            setModalChildComponent("");
            history.go(0);
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <div className={newsPoststyles.newsPostContainer}>
            <div className={newsPoststyles.newsPost}>
                {props.authenticated && <CrudButton classNames={["gold"]} style={EditCrudButtonStyles} onClick={handleEditPostButtonClick}>Edit Post</CrudButton>}
                <div className={newsPoststyles.newsPostTitle}>{props.title}</div>
                <div className={newsPoststyles.newsPostContent}>
                    {props.children}
                </div>
                <div className={`${newsPoststyles.image} ${props.imageUrl == null ? "" : newsPoststyles.active}`} style={{backgroundImage: `url(${props.imageUrl})`}}></div>
                <div className={newsPoststyles.createdAtDate}>Posted on: {formattedCreatedAtDate}</div>
            </div>
            {props.authenticated && <div className={newsPoststyles.deleteButton} onClick={handleDeletePostButtonClick}>X</div>}
        </div>
    );
};

export default NewsPost;