import React, { Component } from "react";
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import schedulePageStyles from '../../styles/SchedulePage.module.css';
import ScheduleItem from '../ScheduleItem';
import CrudButton from '../CrudButton';

class SchedulePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scheduleItems: [],
            newTitle: "",
            newSubtitle: "",
            newContent: "",
        }
    }

    componentDidMount() {
        axios.get('/api/schedules')
        .then(resp => {
            const success = resp.data.success;
            if (success) {
                this.setState({
                    scheduleItems: resp.data.data
                })
            } else {
                return;
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    handleInputChange(e) {
        const target = e.target;
        const name = target.name;
        this.setState({
            ...this.state,
            [name]: e.target.value
        });
    }

    handleSubmitButtonClick(e) {
        e.preventDefault();

        if (this.state.newContent == null || this.state.newContent.length < 1) {
            console.log("Description cannot be empty.");
            return;
        }

        axios.post('/api/schedules', {
            title: this.state.newTitle,
            subtitle: this.state.newSubtitle,
            content: this.state.newContent
        })
        .then(resp => {
            this.setState({
                ...this.state,
                scheduleItems: resp.data.data
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    render() {

        const crudAddButtonStyles = {
            position: "relative",
            marginTop: "10px"
        }

        return (
            <AuthContext.Consumer>
                {({isAuth}) => (
                    <div className="standardPage">
                        <div className="pageContent">
                            <div className="mainSection">
                                <div className="contentHeader">Schedule</div>
                                <div className={schedulePageStyles.textSection}>
                                    {this.state.scheduleItems.map(scheduleItem => {
                                        return (<ScheduleItem key={scheduleItem.id} id={scheduleItem.id} title={scheduleItem.title} subtitle={scheduleItem.subtitle} authenticated={isAuth}>{scheduleItem.content}</ScheduleItem>)
                                    })}
                                    {isAuth &&
                                        <div className={schedulePageStyles.addNewScheduleContainer}>
                                            <div>Add new schedule section:</div>
                                            <input type="text" id="newTitle" name="newTitle" placeholder="Title (Optional)" onChange={(e) => this.handleInputChange(e)}></input>
                                            <input type="text" id="newSubtitle" name="newSubtitle" placeholder="Subtitle (Optional)" onChange={(e) => this.handleInputChange(e)}></input>
                                            <textarea type="text" id="newContent" name="newContent" placeholder="Description" onChange={(e) => this.handleInputChange(e)}></textarea>
                                            <CrudButton classNames={["green"]} style={crudAddButtonStyles} onClick={(e) => this.handleSubmitButtonClick(e)}>Add</CrudButton>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </AuthContext.Consumer>
        );
    }
};

export default SchedulePage;