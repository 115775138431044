import React, { useState, useContext } from 'react';
import navStyles from '../styles/Nav.module.css';
import { Link } from "react-router-dom";
import { AuthContext } from './AuthContext';
import axios from 'axios';

const Nav = (props) => {

    const [state, setState] = useState({
        isMobileMenuActive: false
    });

    const { isAuth, setIsAuth } = useContext(AuthContext);

    let menuClass = navStyles.nav;
    if (state.isMobileMenuActive) {
        menuClass += " " + navStyles.mobileMenuActive;
    }

    const onNavigateButtonClicked = () => {
        setState(state => ({ ...state, isMobileMenuActive: false}));
    }

    return (
        <div className={navStyles.navBg}>
            <nav className={menuClass}>
                <ul>
                    <Link to="/">
                        <li>
                            <div className={navStyles.logo}></div>
                        </li>
                    </Link>
                    <Link to="/" onClick={onNavigateButtonClicked}><li>Home</li></Link>
                    <Link to="/about" onClick={onNavigateButtonClicked}><li>About</li></Link>
                    <Link to="/coaches" onClick={onNavigateButtonClicked}><li>Coaches</li></Link>
                    <Link to="/tryouts" onClick={onNavigateButtonClicked}><li>Tryouts</li></Link>
                    <Link to="/schedule" onClick={onNavigateButtonClicked}><li>Schedule</li></Link>
                    <Link to="/register" onClick={onNavigateButtonClicked}><li>Register</li></Link>
                    <Link to="/gallery" onClick={onNavigateButtonClicked}><li>Gallery</li></Link>
                    <Link to="/contact" onClick={onNavigateButtonClicked}><li>Contact</li></Link>
                    <Link to="/downloads" onClick={onNavigateButtonClicked}><li>Downloads</li></Link>
                </ul>
                <div className={navStyles.actionButtonsContainer}>
                {isAuth === true
                        ?
                        <div className={navStyles.loginButton} onClick={() => {
                            axios.post('/api/users/logout')
                            .then(function (resp) {
                                const success = resp.data.success;
                                if (success) {
                                    setIsAuth(false);
                                } else {
                                    return;
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                        }}>
                            Logout
                        </div>
                        :
                        <div className={navStyles.loginButton}>
                            <Link to="/login" onClick={onNavigateButtonClicked}>Login</Link>
                        </div>
                    }
                    <div className={navStyles.hamburgerButton} onClick={() => {
                        setState(state => ({ ...state, isMobileMenuActive: !state.isMobileMenuActive}));
                    }}>
                        <div className={navStyles.bar}></div>
                        <div className={navStyles.bar}></div>
                        <div className={navStyles.bar}></div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Nav;