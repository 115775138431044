import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './styles/globals.css';
import Nav from './components/Nav';
import LoginPage from './components/pages/LoginPage';
import HomePage from './components/pages/HomePage';
import AboutPage from './components/pages/AboutPage';
import TryoutsPage from './components/pages/TryoutsPage';
import CoachesPage from './components/pages/CoachesPage';
import SchedulePage from './components/pages/SchedulePage';
import ContactPage from './components/pages/ContactPage';
import RegisterPage from './components/pages/RegisterPage';
import DownloadsPage from './components/pages/DownloadsPage';
import GalleryPage from './components/pages/GalleryPage';
import Modal from './components/Modal';
import { ModalContext }  from './components/ModalContext';
import { AuthContext } from './components/AuthContext';
import axios from 'axios';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuth: false,
      setIsAuth: (value) => {
        this.setState(() => ({
          isAuth: value
        }));
      },
      showModal: false,
      modalChildComponent: "",
      setShowModal: (value) => {
          this.setState(() => ({
              showModal: value
          }));
      },
      setModalChildComponent: (value) => {
          this.setState(() => ({
              modalChildComponent: value
          }));
      }
    }
  }

  componentDidMount() {
    axios.get('/api/users/auth')
    .then(resp => {
        const isAuth = resp.data.auth;
        if (isAuth) {
            this.setState({
              isAuth: isAuth
            });
        }
    })
    .catch(error => {
        console.log(error);
    });
  }

  render() {

    const { showModal, modalChildComponent, setShowModal, setModalChildComponent } = this.state;
    const { isAuth, setIsAuth } = this.state;

    return (
      <Router>
        <AuthContext.Provider value={{isAuth, setIsAuth}}>
          <ModalContext.Provider value={{showModal, modalChildComponent, setShowModal, setModalChildComponent}}>
                  <div className="mainWrapper">
                      <div className="mainContainer">
                          <Nav {...this.props} />
                          <div className="container">
                          <Switch>
                            <Route path="/about">
                              <AboutPage />
                            </Route>
                            <Route path="/tryouts">
                              <TryoutsPage />
                            </Route>
                            <Route path="/coaches">
                              <CoachesPage />
                            </Route>
                            <Route path="/schedule">
                              <SchedulePage />
                            </Route>
                            <Route path="/register">
                              <RegisterPage />
                            </Route>
                            <Route path="/gallery">
                              <GalleryPage />
                            </Route>
                            <Route path="/contact">
                              <ContactPage />
                            </Route>
                            <Route path="/downloads">
                              <DownloadsPage />
                            </Route>
                            <Route path="/login">
                              <LoginPage />
                            </Route>
                            <Route exact path="/">
                              <HomePage />
                            </Route>
                          </Switch>
                          </div>
                      </div>
                      {this.state.showModal && 
                      <Modal>{modalChildComponent}</Modal>}
                  </div>
              </ModalContext.Provider>
            </AuthContext.Provider>
      </Router>
    );
  }
}

export default App;
