import React, { useState, useContext } from 'react';
import loginStyles from '../../styles/Login.module.css';
import axios from 'axios';
import { AuthContext } from '../AuthContext';
import CrudButton from '../CrudButton';
import { useHistory } from 'react-router-dom';

function LoginPage(props) {
    
    const [state, setState] = useState({
        email: "",
        password: ""
    });

    const { setIsAuth } = useContext(AuthContext);

    const crudButtonStyles = {
        position: "relative",
        marginTop: "10px"
    }

    const history = useHistory();

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setState(state => ({ ...state, [name]: e.target.value}));
    }
    
    const onLoginButtonClicked = (e) => {
        e.preventDefault();
    
        axios.post('/api/users/login', {
            email: state.email,
            password: state.password
        })
        .then(resp => {
            const success = resp.data.success;
            if (success) {
                setIsAuth(true);
                history.push('/');
            } else {
                setIsAuth(false);
                return;
            }
        })
        .catch(error => {
            console.log(error);
        });
        
    }

    return (
        <div className="pageContent">
            <div className="mainSection">
                <div className={loginStyles.pageTitle}>Login</div>
                <div className={loginStyles.loginContainer}>
                    <input className={loginStyles.input} type="text" id="email" name="email" placeholder="email" value={state.email} onChange={(e) => handleInputChange(e)} />
                    <input className={loginStyles.input} type="password" id="password" name="password" placeholder="password" value={state.password} onChange={(e) => handleInputChange(e)} />
                    <CrudButton classNames={["green"]} style={crudButtonStyles} onClick={(e) => onLoginButtonClicked(e)}>Login</CrudButton>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;