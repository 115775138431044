import React from 'react';
import coachesPageStyles from '../../styles/CoachesPage.module.css';

const CoachesPage = () => {
    return (
        <div className="standardPage">
            <div className="pageContent">
                <div className="mainSection">
                    <div className="contentHeader">Coaches</div>
                    <div className={coachesPageStyles.coachSplashImage}></div>
                </div>
            </div>
        </div>
    );
};

export default CoachesPage;