import React, { useContext } from 'react';
import ModalStyles from '../styles/Modal.module.css';
import { ModalContext }  from '../components/ModalContext';

const Modal = (props) => {

    const { setShowModal } = useContext(ModalContext);
    
    return (
        <div className={ModalStyles.modalOverlay}>
            <div className={ModalStyles.modal}>
                {props.children}
                <div className={ModalStyles.closeButton} onClick={() => setShowModal(false)}>X</div>
            </div>
        </div>
    );
};

export default Modal;